<template>
  <div class="misc-wrapper">
    <brand-misc />

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You are not authorized 🔐
        </h2>
        <p>
          <span v-if="$route.query.reason === 'expiry'">
            Your subscription has ended.
          </span>
          <span v-else>
            You don’t have permission to access this application.
          </span>
          Please contact
          <span v-if="appBrand === 'srp'">
            <b-link href="https://www.structuredretailproducts.com/">SRP</b-link>
          </span>
          <span v-else>
            FVC
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import BrandMisc from '@/views/components/BrandMisc.vue'

export default {
  components: {
    BLink,
    BrandMisc,
  },
  computed: {
    appBrand() {
      return $themeConfig.app.brand
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
